<template>
  <v-dialog
    v-model="show"
    max-width="40vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="name"
                label="Наименование"
                dense
                :error="hasErrorsForProperty(errors.errors, 'name')"
                :error-messages="errorMessages(errors.errors, 'name')"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="type"
                :items="contactTypesDropdown"
                :menu-props="{ maxHeight: '400' }"
                label="Тип"
                dense
                outlined
                persistent-hint
                hide-details
                :rules="[rules.required]"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field v-if="showPhoneInput()"
                            v-model="phoneNumber"
                            :label="type === 'Email' ? 'Почта' : 'Телефонный номер'"
                            dense
                            :error="hasErrorsForProperty(errors.errors, 'phoneNumber')"
                            :error-messages="errorMessages(errors.errors, 'phoneNumber')"
                            outlined
                            :rules="[rules.required]"
              ></v-text-field>
              <v-text-field v-else-if="showLinkInput()"
                            v-model="link"
                            label="Ссылка"
                            dense
                            :error="hasErrorsForProperty(errors.errors, 'link')"
                            :error-messages="errorMessages(errors.errors, 'link')"
                            outlined
                            :rules="[rules.required, rules.urlValidator]"
              ></v-text-field>
            </v-col>

            <v-col
              md="6"
              cols="12"
            >
              <v-checkbox
                v-model="toShowOnMainPage"
                label="Показать на главной странице"
                dense
                :error="hasErrorsForProperty(errors.errors, 'toShowOnMainPage')"
                :error-messages="errorMessages(errors.errors, 'toShowOnMainPage')"
                outlined
              ></v-checkbox>
            </v-col>

            <v-col
              md="6"
              cols="12"
            >
              <v-checkbox
                v-model="isHr"
                label="Отдел кадров"
                dense
                :error="hasErrorsForProperty(errors.errors, 'isHr')"
                :error-messages="errorMessages(errors.errors, 'isHr')"
                outlined
              ></v-checkbox>
            </v-col>

            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import { rules } from '@/helpers/validation'
import { hasErrorsForProperty, errorMessages } from '@/helpers'

export default {
  name: 'ContactForm',
  props: ['save', 'show', 'cancellation', 'label'],
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
    hasErrorsForProperty,
    errorMessages,
  }),
  computed: {
    ...mapFormHandlers('contact/form', [
      'name',
      'link',
      'phoneNumber',
      'toShowOnMainPage',
      'type',
      'isHr',
      'errors',
    ]),
    ...mapGetters('dictionary', { contactTypesDropdown: 'contactTypesDropdown' }),
  },
  async mounted() {
    await this.fetchContactTypes()
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    type: function(newValue) {
      if (newValue === 'PhoneNumber' || newValue === 'WhatsApp' || newValue === 'Email') {
        this.link = null
      } else {
        this.phoneNumber = null
      }
    },
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    showPhoneInput() {
      return this.type === 'PhoneNumber' || this.type === 'WhatsApp' || this.type === 'Email'
    },
    showLinkInput() {
      return !(this.type === 'PhoneNumber' || this.type === 'WhatsApp' || this.type === 'Email')
    },
    ...mapActions('contact/form', ['resetForm']),
    ...mapActions('dictionary', { fetchContactTypes: 'fetchContactTypes' }),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm()
      }
    },
  },
}
</script>

<style scoped>

</style>
